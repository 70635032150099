import React from "react";

interface SpinnerProps {
  size?: "sm" | "md" | "lg" | "xl";
  color?:
    | "primary"
    | "info"
    | "success"
    | "warning"
    | "danger"
    | "slate"
    | "light";
}

const Spinner: React.FC<SpinnerProps> = ({ size = "md", color = "slate" }) => {
  const sizeClasses = {
    sm: "w-6 h-6",
    md: "w-8 h-8",
    lg: "w-12 h-12",
    xl: "w-16 h-16",
  };

  const colorClasses = {
    primary: "border-black",
    info: "border-blue-300",
    success: "border-green-500",
    warning: "border-yellow-500",
    danger: "border-customRed",
    slate: "border-gray-500",
    light: "border-gray-200",
  };

  const ringClasses = `animate-spin rounded-full border-2 border-solid border-t-transparent ${colorClasses[color]}`;

  return (
    <div className={`flex items-center justify-center ${sizeClasses[size]}`}>
      <div className={`${ringClasses} ${sizeClasses[size]}`} />
    </div>
  );
};

export default Spinner;
