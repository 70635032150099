import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FiAlertCircle, FiMail, FiUser } from "react-icons/fi";
import { toast } from "react-toastify";

interface JoinCommunityFormProps {
  first_name: string;
  last_name: string;
  email: string;
}

interface JoinCommunityPopUpFormProps {
  initialValues: JoinCommunityFormProps;
  status: string;
  message: string;
  onValidated: (values: {
    EMAIL: string;
    MERGE1: string;
    MERGE2: string;
  }) => void;
  isVisible: (value: boolean) => void;
}

const JoinCommunityPopUpForm: React.FC<JoinCommunityPopUpFormProps> = ({
  status,
  message,
  onValidated,
  initialValues,
  isVisible,
}) => {
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (status === "success") {
      const timer = setTimeout(() => {
        isVisible(false);
      }, 5000); // Close popup after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [status]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onValidated({
            EMAIL: values.email,
            MERGE1: values.first_name,
            MERGE2: values.last_name,
          });

          setSubmitting(false);
        }}
      >
        <Form className="space-y-4">
          <div className="relative">
            <Field
              type="text"
              id="first_name"
              name="first_name"
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
              placeholder="First Name"
            />
            <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <ErrorMessage name="first_name">
              {(msg) => (
                <div className="text-red-600 text-sm mt-1 flex items-center">
                  <FiAlertCircle size={16} className="mr-1" />
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>

          <div className="relative">
            <Field
              type="text"
              id="last_name"
              name="last_name"
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
              placeholder="Last Name"
            />
            <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <ErrorMessage name="last_name">
              {(msg) => (
                <div className="text-red-600 text-sm mt-1 flex items-center">
                  <FiAlertCircle size={16} className="mr-1" />
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>

          <div className="relative">
            <Field
              type="email"
              id="email"
              name="email"
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
              placeholder="Email"
            />
            <FiMail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <ErrorMessage name="email">
              {(msg) => (
                <div className="text-red-600 text-sm mt-1 flex items-center">
                  <FiAlertCircle size={16} className="mr-1" />
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>

          <button
            type="submit"
            className="w-full bg-[#10152B] text-white py-3 px-4 rounded-xl hover:bg-[#1c2647] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1"
          >
            Subscribe
          </button>
          {status === "success" && (
            <div
              className="bg-green-100 text-green-700 p-3 rounded-lg mb-4"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </Form>
      </Formik>
    </div>
  );
};

const MailchimpPopUp = () => {
  const [isVisible, setIsVisible] = useState(false);

  const initialValues: JoinCommunityFormProps = {
    email: "",
    first_name: "",
    last_name: "",
  };

  const postUrl = `${process.env.REACT_APP_MAILCHIMP_ACTION}?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000); // Show popup after 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 sm:p-8 w-screen !overflow-x-hidden !overflow-y-hidden">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg max-w-md w-full relative sm:mx-auto mx-4 sm:my-auto -mt-[50vh]">
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <FiX size={24} />
        </button>

        <h2 className="text-2xl sm:text-3xl font-bold text-center text-[#10152B] mb-4">
          JOIN OUR COMMUNITY
        </h2>
        <p className="text-gray-600 mb-6 text-center">
          Sign up to be the first to hear about our limited-edition collection,
          exclusive products and more.
        </p>

        <MailchimpSubscribe
          url={postUrl}
          render={({ subscribe, status, message }) => (
            <JoinCommunityPopUpForm
              isVisible={setIsVisible}
              status={status || ""}
              initialValues={initialValues}
              message={message ? message.toString() : ""}
              onValidated={(formData: any) => subscribe(formData)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default MailchimpPopUp;
