import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  IoTrashOutline,
  IoCloudUploadOutline,
  IoRefresh,
  IoAddCircleOutline,
  IoPencil,
} from "react-icons/io5";
import { environment } from "../../environments/environment";
import api from "../../api/api";
import { toast } from "react-toastify";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Spinner from "../../components/ui/Spinner";

interface GalleryImageProp {
  uuid?: string;
  sort_id?: number;
  image_url: string;
  image_description: string;
  image_title: string;
  preview?: string;
}

interface DraggableImageProps {
  image: GalleryImageProp;
  index: number;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
  deleteImage: (index: number) => void;
  onEdit: (index: number) => void;
}

export const AdminGallery: React.FC = () => {
  const [images, setImages] = useState<GalleryImageProp[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingTitle, setEditingTitle] = useState<string>("");
  const [editingDescription, setEditingDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading images...");

  const convertImageToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        const base64String = result.replace("data:", "").replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    setSelectedFiles(files);

    const newImages: GalleryImageProp[] = files.map((file) => ({
      image_url: URL.createObjectURL(file),
      preview: URL.createObjectURL(file),
      image_title: file.name,
      image_description: "Newly added image",
      sort_id: images.length + 1,
    }));

    // console.log(newImages);

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const moveImage = (fromIndex: number, toIndex: number) => {
    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);

    // Update sort_id based on the new order
    const updatedImagesWithSort = updatedImages.map((img, idx) => ({
      ...img,
      sort_id: idx + 1,
    }));
    setImages(updatedImagesWithSort);
  };

  const updateGalleryImages = async () => {
    try {
      setIsLoading(true);
      setLoadingMessage("Uploading gallery images");
      // Convert selected files to base64 if there are any new images added
      const newImagesPayload = await Promise.all(
        selectedFiles.map(async (file, index) => {
          const base64 = await convertImageToBase64(file);
          const imageIndex = images.length - selectedFiles.length + index;
          const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
          return {
            media_type: file.type,
            media_data: base64,
            media_name: fileNameWithoutExtension,
            sort_id: images[imageIndex].sort_id,
            image_title: fileNameWithoutExtension,
            image_description: images[imageIndex].image_description,
          };
        })
      );

      // console.log(newImagesPayload);

      // Create payload for existing images (i.e., those that have been edited)
      const existingImagesPayload = images
        .filter((image) => !image.preview) // Ensure only existing images are included
        .map((image) => ({
          uuid: image.uuid, // Include the uuid to identify the existing image
          sort_id: image.sort_id,
          image_title: image.image_title,
          image_description: image.image_description,
        }));

      // Merge both new and existing images into the payload
      const payload = {
        images: [...newImagesPayload, ...existingImagesPayload],
      };

      // console.log(payload.images);

      // API request to update the gallery
      const response = await api.post(
        `${environment.baseUrl}/admin/gallery/create`,
        payload
      );

      if (response.data.success) {
        toast.success("Gallery images updated successfully");
        fetchAllGalleryImages();
        setSelectedFiles([]); // Clear selected files after successful upload
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setLoadingMessage("Loading images...");
    }
  };

  const fetchAllGalleryImages = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${environment.baseUrl}/gallery`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        const apiResponse = response.data.data.images || {};
        const extractedItems = Object.values(
          apiResponse
        ).flat() as GalleryImageProp[];

        extractedItems.sort((a, b) => (a.sort_id || 0) - (b.sort_id || 0));
        setImages(extractedItems);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteImage = async (index: number) => {
    const imageToDelete = images[index];

    if (imageToDelete.uuid) {
      try {
        const response = await api.delete(
          `${environment.baseUrl}/admin/gallery/delete/${imageToDelete.uuid}`
        );

        if (response.data.success) {
          toast.success(response.data.message);
          setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    }
  };

  const handleEdit = (index: number) => {
    setEditingIndex(index);
    setEditingTitle(images[index].image_title);
    setEditingDescription(images[index].image_description);
  };

  const handleSaveEdit = () => {
    if (editingIndex !== null) {
      const updatedImages = [...images];
      updatedImages[editingIndex] = {
        ...updatedImages[editingIndex],
        image_title: editingTitle,
        image_description: editingDescription,
      };
      setImages(updatedImages);
      setEditingIndex(null);
      setEditingTitle("");
      setEditingDescription("");
    }
  };

  useEffect(() => {
    fetchAllGalleryImages();
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isLoading && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white flex items-center justify-center gap-3 p-6 rounded-lg ">
            {/* <h2 className="text-xl font-bold mb-4">Loading...</h2> */}
            <Spinner color="primary" size="xl" />
            <p>{loadingMessage}</p>
          </div>
        </div>
      )}
      <div className="p-4 relative min-h-screen">
        <h1 className="text-2xl font-bold mb-4">Admin Gallery</h1>

        <div className="flex justify-between items-center mb-6">
          <label className="flex items-center bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 cursor-pointer">
            <IoAddCircleOutline className="mr-2" />
            Select Images
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
            />
          </label>
          <button
            className="flex items-center bg-gray-200 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-300"
            onClick={fetchAllGalleryImages}
          >
            <IoRefresh className="mr-2" />
            Refresh Gallery
          </button>
        </div>

        <div className="grid grid-cols-3 gap-4 mb-10">
          {images.map((image, index) => (
            <DraggableImage
              key={index}
              index={index}
              image={image}
              moveImage={moveImage}
              deleteImage={deleteImage}
              onEdit={handleEdit}
            />
          ))}
        </div>

        <div>
          <button
            className="flex items-center justify-center w-full bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800"
            onClick={updateGalleryImages}
          >
            <IoCloudUploadOutline className="mr-2" />
            Upload Images
          </button>
        </div>

        {editingIndex !== null && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg max-w-md w-full">
              <h2 className="text-xl font-bold mb-4">Edit Image</h2>
              <input
                type="text"
                value={editingTitle}
                onChange={(e) => setEditingTitle(e.target.value)}
                className="w-full mb-4 p-2 border rounded"
                placeholder="Image Title"
              />
              <textarea
                value={editingDescription}
                onChange={(e) => setEditingDescription(e.target.value)}
                className="w-full mb-4 p-2 border rounded"
                placeholder="Image Description"
                rows={3}
              />
              <div className="flex justify-end">
                <button
                  onClick={handleSaveEdit}
                  className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
                >
                  Save
                </button>
                <button
                  onClick={() => setEditingIndex(null)}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

const DraggableImage: React.FC<DraggableImageProps> = ({
  image,
  index,
  moveImage,
  deleteImage,
  onEdit,
}) => {
  const [, ref] = useDrag({
    type: "IMAGE",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "IMAGE",
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const cloudinaryImageUrl = image.image_url.includes("cloudinary.com")
    ? image.image_url.replace("/upload/", "/upload/w_498,h_256,c_fit,f_auto/")
    : image.image_url;

  return (
    <div ref={(node) => ref(drop(node))} className="relative cursor-move">
      <img
        src={image.preview || cloudinaryImageUrl}
        alt={image.image_title}
        className="w-full h-64 object-cover rounded"
      />
      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
        <h3 className="font-bold">{image.image_title}</h3>
        <p className="text-sm">{image.image_description}</p>
      </div>
      <button
        className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
        onClick={() => deleteImage(index)}
      >
        <IoTrashOutline />
      </button>
      <button
        className="absolute top-2 right-12 bg-blue-500 text-white p-2 rounded-full"
        onClick={() => onEdit(index)}
      >
        <IoPencil />
      </button>
    </div>
  );
};
